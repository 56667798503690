import React, { useState, useEffect } from "react";
import "./CheckoutWallet.scss";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Pb from "../../../img/pb.png";
import Pb_qr from "../../../img/pb_qr.png";
import MachQr from "../../../img/mach_qr.png";
import VitaQr from "../../../img/vita_qr.png";
import AmeQr from "../../../img/ameqr.png";
import TpagaQr from "../../../img/tpaga_qr.jpg";
import AlipayQr from "../../../img/alipay_qr.png";
import AlipayPlus from "../../../img/alipay_plus.png";
import AlipayhkLogo from "../../../img/alipayhk_logo.svg";
import GcashLogo from "../../../img/gcash_aliplus.svg";
import KakaopayLogo from "../../../img/kakaopay_aliplus.svg";
import TngLogo from "../../../img/tng_logo.svg";
import AlipayLogo from "../../../img/alipay_logo.svg";
import TruemoneyLogo from "../../../img/truemoney_logo.svg";
import RabbitLineLogo from "../../../img/rabbitline_logo.svg";
import { getWalletChannelLogo, walletChannels } from "../../../utils/configurations";
import { FormatMoney } from "../../../utils/formats";
import CountDown from "../../comp/CountDown";
import CopyButton from "../../comp/CopyButton";
import Grab from "../../../img/grab.jpg";
import Ovo from "../../../img/ovo.png";
import SBPIcon from "../../../img/sbp.svg";
import SberPayIcon from "../../../img/sber-pay.svg";
import SberPayQr from "../../../img/sberpay-qr.jpg";

var QRCode = require("qrcode.react");

const getLogo = {
  "SBP-PGOne": SBPIcon,
  "SberPay-PGOne": SberPayIcon,
};

const channel2QrLogo = {
  Mach: MachQr,
  Vita: VitaQr,
  AME: AmeQr,
  AlipayCN: AlipayQr,
  AlipayHK: AlipayQr,
  GCash: AlipayQr,
  GrabPay: AlipayQr,
  OVO: AlipayQr,
  KakaoPayAlipay: AlipayQr,
  TNG: AlipayQr,
  TrueMoney: AlipayQr,
  RabbitLinePay: AlipayQr,
  TPaga: TpagaQr,
  BS2: Pb_qr,
  "SBP-PGOne": SBPIcon,
  "SberPay-PGOne": SberPayQr,
};

const alipayChannel2Logo = {
  AlipayHK: AlipayhkLogo,
  GCash: GcashLogo,
  KakaoPayAlipay: KakaopayLogo,
  TNG: TngLogo,
  GrabPay: Grab,
  OVO: Ovo,
  TrueMoney: TruemoneyLogo,
  AlipayCN: AlipayLogo,
  RabbitLinePay: RabbitLineLogo,
};

function CheckoutWallet(props) {
  const { t } = useTranslation();
  const [isQrExpired, setIsQrExpired] = useState(false);
  useEffect(() => {
    setIsQrExpired(!!localStorage?.getItem("PS_" + props.prepayId + "qr_expired"));
  }, []);
  const isProviderAlipay = [
    "AlipayCN",
    "AlipayHK",
    "GCash",
    "KakaoPayAlipay",
    "TNG",
    "TrueMoney",
    "RabbitLinePay",
    "OVO",
    "GrabPay",
  ].includes(props.payChannel);

  const getQrData = {
    Mach: props.payData?.app_link_url,
    Vita: props.payData?.app_link_url,
    AME: props.payData?.wallet_url,
    AlipayCN: props.payData?.qr_code,
    AlipayHK: props.payData?.qr_code,
    GrabPay: props.payData?.qr_code,
    OVO: props.payData?.qr_code,
    GCash: props.payData?.qr_code,
    KakaoPayAlipay: props.payData?.qr_code,
    TNG: props.payData?.qr_code,
    TrueMoney: props.payData?.qr_code,
    RabbitLinePay: props.payData?.qr_code,
    TPaga: props.payData?.wallet_url,
    BS2: props.payData?.qr_code,
    "SBP-PGOne": props.payData?.qr_code,
    "SberPay-PGOne": props.payData?.pay_url,
  };
  const getLink = {
    Mach: props.payData?.app_link_url,
    Vita: props.payData?.app_link_url,
    AME: props.payData?.app_link_url,
    AlipayCN: props.payData?.wallet_url,
    AlipayHK: props.payData?.wallet_url,
    GrabPay: props.payData?.wallet_url,
    OVO: props.payData?.wallet_url,
    GCash: props.payData?.wallet_url,
    KakaoPayAlipay: props.payData?.wallet_url,
    TNG: props.payData?.wallet_url,
    TrueMoney: props.payData?.wallet_url,
    RabbitLinePay: props.payData?.wallet_url,
    TPaga: props.payData?.wallet_url,
    BS2: `cb.playersbank://pix/qrcode/pay?emv=${props.payData?.qr_code}`,
    Fpay: props.payData?.qr_code_url,
    "SBP-PGOne": props.payData?.qr_code,
    "SberPay-PGOne": props.payData?.pay_url,
  };
  return (
    <div className="checkoutWallet">
      <div className="checkoutWallet__title">
        <p className="checkoutWallet--pc">{t("wallet_inst1")}</p>
        <p className="checkoutWallet--mobile">{t("wallet_mob_ins1")}</p>
      </div>
      <div className="checkoutWallet__content">
        <div className="checkoutWallet__content__qrContent">
          <p className="checkoutWallet__content__total">
            <FormatMoney type="total" />
          </p>
          {(((props.payChannel === "Mach" || props.payChannel === "Vita") && props.payData?.app_link_url) ||
            (props.payChannel === "AME" && props.payData?.wallet_url) ||
            (props.payChannel === "AlipayCN" && props.payData?.qr_code) ||
            (props.payChannel === "AlipayHK" && props.payData?.qr_code) ||
            (props.payChannel === "GrabPay" && props.payData?.qr_code) ||
            (props.payChannel === "OVO" && props.payData?.qr_code) ||
            (props.payChannel === "GCash" && props.payData?.qr_code) ||
            (props.payChannel === "KakaoPayAlipay" && props.payData?.qr_code) ||
            (props.payChannel === "TNG" && props.payData?.qr_code) ||
            (props.payChannel === "TrueMoney" && props.payData?.qr_code) ||
            (props.payChannel === "RabbitLinePay" && props.payData?.qr_code) ||
            (props.payChannel === "TPaga" && props.payData?.wallet_url) ||
            (props.payChannel === "BS2" && props.payData?.qr_code) ||
            (props.payChannel === "Fpay" && props.payData?.qr_code_url) ||
            (props.payChannel === "PGOne" && props.payData?.qr_code) ||
            (props.payChannel === "PGOne" && props.payData?.pay_url)) && (
            <div className="checkoutWallet__content__qrContainer">
              {props.payChannel === "Fpay" ? (
                isQrExpired ? (
                  <QRCode
                    className="checkoutWallet__content__qr"
                    style={{ filter: "blur(3px)" }}
                    value={"Expired!"}
                    size={160}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"Q"}
                    includeMargin={false}
                    renderAs={"svg"}
                    imageSettings={{
                      src: channel2QrLogo[props.payChannel],
                      x: null,
                      y: null,
                      height: 24,
                      width: 24,
                      excavate: true,
                    }}
                  />
                ) : (
                  <iframe
                    src={props.payData?.qr_code_url}
                    frameborder="0"
                    title="FpayIframe"
                    height="280"
                    width="300"
                  />
                )
              ) : (
                <QRCode
                  className="checkoutWallet__content__qr"
                  style={{ filter: isQrExpired ? "blur(3px)" : "blur(0)" }}
                  value={getQrData[`${props.method}-${props.payChannel}`] || getQrData[props.payChannel]}
                  size={160}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"Q"}
                  includeMargin={false}
                  renderAs={"svg"}
                  imageSettings={{
                    src: channel2QrLogo[`${props.method}-${props.payChannel}`] || channel2QrLogo[props.payChannel],
                    x: null,
                    y: null,
                    height: 24,
                    width: 24,
                    excavate: true,
                  }}
                />
              )}

              {isQrExpired && <span className="checkoutWallet__content__qrNotice">QR Code Expired</span>}
            </div>
          )}
          {(isProviderAlipay || props.payChannel === "Fpay") && !isQrExpired && (
            <p className="checkoutWallet__content__countDown">
              Order will expire in{" "}
              <CountDown
                time={props.payData?.due_date ? props.payData?.due_date * 1000 - Date.now() : 600000}
                timeIsUpCallback={() => {
                  setIsQrExpired(true);
                  localStorage?.setItem("PS_" + props.prepayId + "qr_expired", JSON.stringify(true));
                }}
              />
            </p>
          )}
          {props.method === "PlayersBankPIX" && (
            <CopyButton text={getQrData[`${props.method}-${props.payChannel}`] || getQrData[props.payChannel]} />
          )}
        </div>
        <div className="checkoutWallet__content__inst">
          <div className="checkoutWallet__content__inst__titleContainer">
            <p className="checkoutWallet__content__inst__title checkoutWallet--pc">{t("wallet_inst2")}</p>
            <p className="checkoutWallet__content__inst__title checkoutWallet--mobile">{t("wallet_mob_ins2")}</p>
            <img
              src={
                props.method === "PlayersBankPIX"
                  ? Pb
                  : isProviderAlipay
                  ? alipayChannel2Logo[props.payChannel]
                  : getLogo[`${props.method}-${props.payChannel}`] || getWalletChannelLogo(props.payChannel)
              }
              alt=""
              style={{ height: "25px" }}
            />
          </div>
          <div className="checkoutWallet__content__inst__howToPay">
            <p className="checkoutWallet__content__inst__howToPay__title">{t("wallet_inst3")}</p>
            {props.method === "PlayersBankPIX" ? (
              <div>
                <div className="checkoutWallet--pc">
                  <p>{t("pb_ins")}</p>
                </div>
                <div className="checkoutWallet--mobile">
                  <p>{t("pb_mob_ins")}</p>
                </div>
              </div>
            ) : (
              <div>
                <div className="checkoutWallet--pc">
                  <p>{t("wallet_inst4")}</p>
                  <p>{t("wallet_inst5")}</p>
                  <p>{t("wallet_inst6")}</p>
                </div>
                <div className="checkoutWallet--mobile">
                  <p>
                    <span>{t("wallet_mob_ins3")}</span>
                  </p>
                  <p>{t("wallet_mob_ins4")}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {props.payChannel !== "Fpay" && (
        <div className="checkoutWallet__buttonContainer">
          <button
            onClick={() => {
              window.open(getLink[props.payChannel], "_blank");
            }}
          >
            {t("go_to")}
          </button>
        </div>
      )}
      {isProviderAlipay && (
        <div className="checkoutWallet__alipayPartner">
          <span>Powered by</span>&nbsp;&nbsp;
          <img src={AlipayPlus} alt="" />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
    payData: state.payData,
    prepayId: state.prepayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutWallet);
