import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "../language/i18n";
import uuid from "react-uuid";
import OnlineBanking from "../img/online_banking.svg";
import CashPayments from "../img/cash_payments.svg";
import CreditCard from "../img/credit_card.svg";
import Spei from "../img/spei.png";
import Oxxo from "../img/oxxo.png";
import Todito from "../img/todito.png";
import Efecty from "../img/efecty.png";
import PSE from "../img/pse.png";
import Khipu from "../img/khipu.png";
import CashPaymentsM from "../img/cash_mobile.jpg";
import EfectyM from "../img/efecty_mobile.png";
import PseM from "../img/pse_mobile.png";
import KhipuM from "../img/khipu_mobile.png";
import ToditoM from "../img/todito_mobile.png";
import ToditoCash from "../img/todito_cash.png";
import Paypal from "../img/paypal.png";
import Ame from "../img/ame.png";
import Picpay from "../img/picpay.png";
import Boleto from "../img/boleto.svg";
import WalletChl from "../img/wallet_chl.png";
import WalletChl2 from "../img/wallet_chl2.png";
import Mach from "../img/mach.png";
import Vita from "../img/vita.png";
import BoletoRapidao from "../img/boleto_rapidao.png";
import DepositExpress from "../img/deposit_express6.svg";
import Lottery from "../img/lottery.svg";
import Pix from "../img/pix2.svg";
import Itau from "../img/itau.png";
import Santander from "../img/santander.png";
import BancoDoBrasil from "../img/banco-do-brasil.png";
import Bradesco from "../img/bradesco.png";
import Caixa from "../img/caixa.png";
import Alipayhk from "../img/alipayhk_alipay-partner.png";
import AlipayhkLogo from "../img/alipayhk_logo.svg";
import AlipayLogo from "../img/alipay_logo.svg";
import Alipay from "../img/alipay.svg";
import Gcash from "../img/gcash.png";
import BoostAlipay from "../img/boost_alipay.png";
import GcashAlipay from "../img/gcash_alipay-partner.png";
import KakaoPayAlipay from "../img/kakaopay_alipay-partner.png";
import KakaoPay from "../img/kakaopay.png";
import KakaopayLogo from "../img/kakaopay_logo.svg";
import Dana from "../img/dana_alipay-partner.png";
import DanaLogo from "../img/dana_logo.svg";
import Tng from "../img/tng_alipay-partner.png";
import TngLogo from "../img/tng_logo.svg";
import Truemoney from "../img/truemoney_alipay-partner.png";
import Paymaya from "../img/paymaya.jpg";
import GcashLogo from "../img/gcash_logo.svg";
import TpagaLogo from "../img/tpaga_logo.jpg";
import DebitPh from "../img/debitph.png";
import Ubp from "../img/ubp.png";
import Bpi from "../img/bpi.png";
import Pipay from "../img/pipay.png";
import Paygo from "../img/paygo.png";
import Tpaga from "../img/tpaga.jpg";
import Seven11 from "../img/seven11.png";
import Seven11M from "../img/seven11.svg";
import PeScotia from "../img/pe_scotiabank.jpg";
import PePambo from "../img/pe_pambo.jpg";
import PeWu from "../img/pe_wu.jpg";
import PeBanbif from "../img/pe_banbif.jpg";
import PeBbva from "../img/pe_bbva.jpg";
import PeBcp from "../img/pe_bcp.jpg";
import PeCaja from "../img/pe_caja.jpg";
import PeDisashop from "../img/pe_disashop.jpg";
import PeInterbank from "../img/pe_interbank.jpg";
import PeKasnet from "../img/pe_kasnet.jpg";
import PeCellPower from "../img/pe_cellpower.jpg";
import PeGanaste from "../img/pe_ganaste.jpg";
import PeMoneyGram from "../img/pe_moneygram.jpg";
import PeNiuBiz from "../img/pe_niubiz.jpg";
import PePichinca from "../img/pe_pichinca.jpg";
import PeRedDigit from "../img/pe_reddigit.jpg";
import PeBanbifWallet from "../img/banbifwallet.png";
import PeBbvaWallet from "../img/bbvawallet.png";
import InterbankWallet from "../img/interbankwallet.png";
import LigoWallet from "../img/ligowallet.png";
import PlinWallet from "../img/plinwallet.png";
import ScotiabankWallet from "../img/scotiabankwallet.png";
import TunkiWallet from "../img/tunkiwallet.png";
import YapeWallet from "../img/yapewallet.png";
import Wallet from "../img/wallet.svg";
import WalletM from "../img/wallet_mobile.png";
import Grab from "../img/grab.jpg";
import Paynow from "../img/paynow.png";
import RabbitLineAc from "../img/rabbitline_ac.png";
import BpiAc from "../img/BPI_ac.png";
import Mada from "../img/mada.jpg";
import Knet from "../img/knet.jpg";
import UPI from "../img/UPI.png";
import Netbank from "../img/netbank.png";
import AlfaClick from "../img/alfa_click.png";
import Qiwi from "../img/qiwi.png";
import WebMoney from "../img/webmoney.png";
import YandexMoney from "../img/yandex_money.png";
import PagoEfectivo from "../img/pago_efectivo.png";
import Yoomoney from "../img/yoomoney.png";
import WalletRu from "../img/yoomoney_logo.png";
import Eprepag from "../img/eprepag.png";
import Paysafecard from "../img/paysafecard.png";
import Skrill from "../img/skrill.png";
import Fawry from "../img/fawry.png";
import Baloto from "../img/baloto.png";
import OxxoPay from "../img/oxxo_pay.png";
import UnionPay from "../img/union_pay.png";
import AlipayPlus from "../img/alipay_plus.png";
import AlipayPlusLogo from "../img/alipay_plus_52.png";
import Codi from "../img/codi.png";
import CodiM from "../img/codiM.png";
import Payco from "../img/payco.png";
import Toss from "../img/toss.png";
import SamsungPay from "../img/samsungPay.png";
import VirtualAccount from "../img/virtual_account.png";
import Chek from "../img/chek.png";
import Sured from "../img/sured.png";
import Gana from "../img/gana.png";
import Pago46 from "../img/pago46.png";
import PlayersBank from "../img/pb.svg";
import KiwiWallet from "../img/kiwi_wallet.png";
import PagoFacil from "../img/pago_facil.png";
import RapiPago from "../img/rapi_pago.png";
import Brou from "../img/banco_republica.jpg";
import Bancontact from "../img/bancontact.png";
import Giropay from "../img/giropay.jpg";
import Ideal from "../img/ideal.png";
import MyBank from "../img/myBank.jpg";
import Neosurf from "../img/neosurf.png";
import Przelewy from "../img/przelewy.png";
import Sofort from "../img/sofort.png";
import Trustly from "../img/trustly.png";
import EPS from "../img/eps.png";
import Transfiya from "../img/transfiya.png";
import AstroPay from "../img/astropay.png";
import Nequi from "../img/nequi.png";
import Rapipay from "../img/rapipay.png";
import Movii from "../img/movii.png";
import Daviplata from "../img/daviplata.png";
import Dale from "../img/dale.png";
import Bancolombia from "../img/bancolombia.png";
import Fpay from "../img/fpay.png";
import BCR from "../img/bcr.png";
import BNCR from "../img/bncr.png";
import ClaroPay from "../img/claro_pay.png";
import Servipag from "../img/servipag.png";
import ServipagPc from "../img/servipagpc.png";
import Webpay from "../img/webpay.png";
import NaverPay from "../img/npay.png";
import Crypto from "../img/crypto.png";
import MpWallet from "../img/mp_wallet.png";
import GrabAlipay from "../img/grab_alipay.jpg";
import OvoAlipay from "../img/ovo_alipay.jpg";
import ToditoCashLogo from "../img/toditocash_logo.png";
import OpenFinance from "../img/openfinance.svg";
import Tupay from "../img/tupay-channels.png";
import Linepay from "../img/linepay.png";
import RetailStore from "../img/retail-store.png";
import ShopeePay from "../img/shopeepay.png";
import Payconiq from "../img/payconiq.png";
import Multibanco from "../img/multibanco.png";
import SBP from "../img/sbp.svg";

import MOL from "../img/boku.png";
import Klap from "../img/klap.png";
import Pc1 from "../img/pc1.jpg";
import Pc2 from "../img/pc2.jpg";
import Pc3 from "../img/pc3.jpg";
import Pc4 from "../img/pc4.jpg";
import Pc7 from "../img/pc7.jpg";
import Pc9 from "../img/pc9.jpg";
import Pc10 from "../img/pc10.jpg";
import Pc11 from "../img/pc11.jpg";

import PcMex1 from "../img/pcMEX1.jpg";
import PcMex2 from "../img/pcMEX2.jpg";

import PcMex4 from "../img/pcMEX4.jpg";
import PcMex5 from "../img/pcMEX5.jpg";
import PcMex6 from "../img/pcMEX6.jpg";
import PcMex7 from "../img/pcMEX7.jpg";
import PcMex8 from "../img/pcMEX8.jpg";
import PcMex9 from "../img/pcMEX9.jpg";
import PcMex10 from "../img/pcMEX10.jpg";
import PcMex11 from "../img/pcMEX11.jpg";
import PcMex12 from "../img/pcMEX12.jpg";
import PcMex13 from "../img/pcMEX13.jpg";
import PcMex14 from "../img/pcMEX14.jpg";

import Ahorro from "../img/ahorro.jpg";
import Alianza from "../img/alianza.jpg";
import Banbajio from "../img/banbajio.jpg";
import Banorte from "../img/banorte.jpg";
import Bogeda from "../img/bogeda.jpg";
import Hsbc from "../img/hsbc.jpg";
import Kiosko from "../img/kiosko.jpg";
import Lagunitas from "../img/lagunitas.jpg";
import Merza from "../img/merza.jpg";
import Morelia from "../img/morelia.jpg";
import Oblatos from "../img/oblatos.jpg";
import Smbrural from "../img/smbrural.jpg";
import Telecom from "../img/telecom.jpg";
import Via from "../img/Via.jpg";
import Yza from "../img/yza.jpg";
import Hey from "../img/hey.jpg";
import CruzAzul from "../img/cruz_azul.jpg";
import SamsClub from "../img/sams_club.jpg";
import Papara from "../img/papara.png";

import Ficohsa from "../img/ficohsa.jpg";
import InterBanco from "../img/inter_banco.jpg";

import AgenteCash from "../img/agente_cash.jpg";
import Comercio from "../img/comercio.jpg";
import Edypyme from "../img/edypyme.jpg";
import Megapunto from "../img/megapunto.jpg";
import Sullana from "../img/sullana.jpg";

import Azuayo from "../img/azuayo.jpg";
import BancoInternacional from "../img/banco_internacional.jpg";
import Coac29 from "../img/coac29.jpg";
import Coopmego from "../img/coopmego.jpg";
import Economicas from "../img/economicas.jpg";
import Facilito from "../img/facilito.jpg";
import Guayaquil from "../img/guayaquil.jpg";
import Hiper_market from "../img/hiper_market.jpg";
import Matualista from "../img/matualista.jpg";
import Muchuc from "../img/muchuc.jpg";
import Afirme from "../img/afirme.jpg";

import CriGec from "../img/cri_gec.jpg";
import CriLafise from "../img/cri_lafise.jpg";
import CriNacional from "../img/cri_nacional.jpg";
import CriPayser from "../img/cri_payser.jpg";
import CriTeledolar from "../img/cri_teledolar.jpg";

import GtmBam from "../img/gtm_bam.jpg";
import GtmBanrural from "../img/gtm_banrural.jpg";
import GtmBantrab from "../img/gtm_bantrab.jpg";
import GtmContinental from "../img/gtm_continental.jpg";

import PanMachetazo from "../img/pan_machetazo.jpg";
import PanXtra from "../img/pan_xtras.jpg";
import PanJuan from "../img/juan.png";
import Pharmacys from "../img/pharmacys.png";
import Servientrega from "../img/servientrega.png";
import Pronet from "../img/pronet.png";
import JumboMarket from "../img/jumbo-market.jpg";
import FarmaExpress from "../img/farma-express.jpg";
import AmPm from "../img/am-pm.jpg";

import PerAndes from "../img/pe_andes.jpg";
import PerCajadel from "../img/pe_cajadel.jpg";
import PerCajapalla from "../img/pe_cajapalla.jpg";
import PerFullcargo from "../img/pe_fullcargo.jpg";
import PerQapaq from "../img/pe_qapaq.jpg";
import PerRaiz from "../img/pe_raiz.jpg";
import PerTrujillo from "../img/pe_trujillo.jpg";
import Izipay from "../img/izipay.png";
import SberPay from "../img/sber-pay.svg";

import Btc from "../img/btc.jpg";
import BtcLight from "../img/btclight.png";
import Eth from "../img/eth.png";
import Usdc from "../img/usdc.png";
import UsdtErc from "../img/usdt_erc.png";
import UsdtTrc from "../img/usdt_trc.png";
import store from "../redux/store";

// method-channel pair for /checkout page
const checkoutConfig = [
  "BankTransfer-Monnet",
  "Cash-Monnet",
  "BankTransfer-KASHIO",
  "Cash-KASHIO",
  "Cash-SafetyPay",
  "BankTransfer-SafetyPay",
  "SPEI-OpenPay",
  "SPEI-STP",
  "SPEI-Banwire",
  "Cash-Banwire",
  "Cash-OpenPay",
  "OXXO-Banwire",
  "OXXO-CardPay",
  "OXXO-Bamboo",
  "OXXO-Localpayment",
  "Wallet-Mach",
  "Wallet-Vita",
  "Wallet-AME",
  "Wallet-AlipayCN",
  "Wallet-AlipayHK",
  "Wallet-GCash",
  "Wallet-KakaoPayAlipay",
  "Wallet-TNG",
  "Wallet-TrueMoney",
  "Wallet-TPaga",
  "Wallet-RabbitLinePay",
  "Wallet-Fpay",
  "Wallet-ShopeePay",
  "Wallet-FawryPay",
  "Boleto-BS2",
  "Boleto-StarkBank",
  "Boleto-Mercadopago",
  "BoletoRapido-BS2",
  "Boleto-CHANNEL_GROUP_ROUTER",
  "PIX-BS2",
  "PIX-Bradesco",
  "PIX-Rendimento",
  "PIX-Nupay",
  "PIX-Genial",
  "PIX-StarkBank",
  "PIX-StarkBankTransferSmile",
  "PIX-CHANNEL_GROUP_ROUTER",
  "PIX-Mercadopago",
  "PIX-BTG",
  "PIX-Fastcash",
  "PIX-Pagsmile",
  "PIX-Delbank",
  "DepositExpress-Levpay",
  "OTC-Xendit",
  "Cash-PayCash",
  "Lottery-Caixa",
  "Lottery-Mercadopago",
  "OXXOPay-Conekta",
  "Baloto-PayValida",
  "Baloto-IGT",
  "SuRed-PayValida",
  "GANA-PayValida",
  "Bancolombia-PayValida",
  "BCR-PayValida",
  "BNCR-PayValida",
  "BNCR-PayCash",
  "KLAP-Multicaja",
  "Cash-Multicaja",
  "PlayersBankPIX-BS2",
  "BankTransfer-Localpayment",
  "PagoFacil-Localpayment",
  "PagoFacil-PagoFacil",
  "Servipag-Localpayment",
  "Rapipago-Localpayment",
  "Rapipago-Rapipago",
  "Transfiya-Movii",
  "Cash-Localpayment",
  "CoDi-STP",
  "Crypto-TripleA",
  "Wallet-Tupay",
  "Multibanco-PPRO",
  "Cash-FawryPay",
  "SBP-PGOne",
  "SberPay-PGOne",
];

const channelConfig = (method, channel) => {
  let config = [
    "Cash-SafetyPay",
    "Cash-PagoEfectivo",
    "BankTransfer-PagoEfectivo",
    "BankTransfer-SafetyPay",
    "Khipu",
    "Wallet",
    "DepositExpress",
    "DirectDebit",
    "PagoEfectivo",
    "Crypto",
  ]; // methods that have channels to be choosen
  return config.indexOf(method) >= 0 || config.indexOf(method + "-" + channel) >= 0;
};

const walletLogo = {
  MEX: { pc: Wallet, mobile: Wallet },
  BRA: { pc: Wallet, mobile: Wallet },
  CHL: { pc: Wallet, mobile: Wallet },
  HKG: { pc: AlipayhkLogo, mobile: AlipayhkLogo },
  PHL: { pc: Wallet, mobile: Wallet },
  KOR: { pc: Wallet, mobile: Wallet },
  IDN: { pc: Wallet, mobile: Wallet },
  MYS: { pc: Wallet, mobile: Wallet },
  THA: { pc: Wallet, mobile: Wallet },
  COL: { pc: Wallet, mobile: Wallet },
  CHN: { pc: AlipayLogo, mobile: AlipayLogo },
  SGP: { pc: Wallet, mobile: Wallet },
  SAU: { pc: Wallet, mobile: Wallet },
  KWT: { pc: Wallet, mobile: Wallet },
  RUS: { pc: Wallet, mobile: Wallet },
  EGY: { pc: Wallet, mobile: Wallet },
  PER: { pc: Wallet, mobile: Wallet },
  ARG: { pc: Wallet, mobile: Wallet },
  TWN: { pc: Wallet, mobile: Wallet },
  TUR: { pc: Wallet, mobile: Wallet },
  VNM: { pc: Wallet, mobile: Wallet },
};

export const getProviderId = () => {
  const { tradeInfo, method, payChannel } = store.getState();
  const channelList = tradeInfo.channel_info_list;
  const providerId = channelList.find((obj) => obj.channel === payChannel && obj.method === method)?.provider_id;
  return providerId;
};

const getWalletChannelLogo = (channel) => {
  const { channel_info_list: channelList = [] } = store.getState().tradeInfo || {};
  const providerId = channelList.find((obj) => obj.channel === channel)?.provider_id;
  const walletLogos = {
    Todito: ToditoCash,
    AstroPay: AstroPay,
    Paypal: Paypal,
    PaypalXCG: Paypal,
    AME: Ame,
    PicPay: Picpay,
    Eprepag: Eprepag,
    MOL: MOL,
    Paysafecard: Paysafecard,
    Skrill: Skrill,
    Mercadopago: MpWallet,
    Mach: Mach,
    Vita: Vita,
    Chek: Chek,
    Fpay: Fpay,
    AlipayHK: Alipayhk,
    GCash: { 1075: GcashAlipay, 1161: GcashAlipay, 1152: Gcash, 1167: GcashAlipay },
    GcashXendit: Gcash,
    Paymaya: Paymaya,
    BPI: BpiAc,
    KakaoPayAlipay: KakaoPayAlipay,
    TOSS: Toss,
    PAYCO: Payco,
    SamsungPay: SamsungPay,
    KakaoPay: KakaoPay,
    NaverPay: NaverPay,
    DANA: Dana,
    OVO: OvoAlipay,
    TNG: Tng,
    BOOST: BoostAlipay,
    TrueMoney: Truemoney,
    RabbitLinePay: RabbitLineAc, // Alipay +
    LINEPay: Linepay, // HappyPay
    TPaga: Tpaga,
    Daviplata: Daviplata,
    Rappipay: Rapipay,
    Dale: Dale,
    Movii: Movii,
    Nequi: Nequi,
    ClaroPay: ClaroPay,
    AlipayCN: Alipay,
    GrabPay: { 1141: GrabAlipay, 1067: Grab },
    PayNow: Paynow,
    MADA: Mada,
    KNet: Knet,
    YooMoney: Yoomoney,
    QIWI: KiwiWallet,
    Fawry: Fawry,
    FawryPay: Fawry,
    Tupay: Tupay,
    Papara: Papara,
    ShopeePay: ShopeePay,
  };
  if (typeof walletLogos[channel] === "object") return walletLogos[channel]?.[providerId];
  // console.log(`channel: ${channel}, 1. ${walletLogos[channel]?.[providerId]} 2. ${walletLogos[channel]}`);
  return walletLogos[channel];
};

const walletChannels = {
  MEX: { Todito: ToditoCash, AstroPay: AstroPay, Paypal: Paypal },
  BRA: {
    Paypal: Paypal,
    PaypalXCG: Paypal,
    AME: Ame,
    PicPay: Picpay,
    Eprepag: Eprepag,
    MOL: MOL,
    Paysafecard: Paysafecard,
    Skrill: Skrill,
    AstroPay: AstroPay,
    Mercadopago: MpWallet,
  },
  CHL: { Mach: Mach, Vita: Vita, Chek: Chek, Fpay: Fpay },
  PER: { Fpay: Fpay },
  HKG: { AlipayHK: Alipayhk },
  PHL: {
    GCash: GcashAlipay,
    GcashXendit: Gcash,
    Paymaya: Paymaya,
    BPI: BpiAc,
  },
  KOR: {
    KakaoPayAlipay: KakaoPayAlipay,
    TOSS: Toss,
    PAYCO: Payco,
    SamsungPay: SamsungPay,
    KakaoPay: KakaoPay,
    NaverPay: NaverPay,
  },
  IDN: { DANA: Dana, OVO: OvoAlipay },
  MYS: { TNG: Tng, BOOST: BoostAlipay, GrabPay: GrabAlipay },
  THA: {
    TrueMoney: Truemoney,
    RabbitLinePay: RabbitLineAc,
  },
  COL: {
    TPaga: Tpaga,
    Daviplata: Daviplata,
    Rappipay: Rapipay,
    Dale: Dale,
    Movii: Movii,
    Nequi: Nequi,
    ClaroPay: ClaroPay,
  },
  ARG: { AstroPay: AstroPay },
  CHN: { AlipayCN: Alipay },
  SGP: { GrabPay: Grab, PayNow: Paynow },
  SAU: { MADA: Mada },
  KWT: { KNet: Knet },
  RUS: { YooMoney: Yoomoney, QIWI: KiwiWallet },
  EGY: { Fawry: Fawry },
};

const cryptoChannels = [
  {
    data: [{ logo: Btc, channel: "BTC" }],
    key: uuid(),
  },
  {
    data: [{ logo: BtcLight, channel: "LNBC" }],
    key: uuid(),
  },
  {
    data: [{ logo: Eth, channel: "ETH" }],
    key: uuid(),
  },
  {
    data: [{ logo: Usdc, channel: "USDC" }],
    key: uuid(),
  },
  {
    data: [{ logo: UsdtErc, channel: "USDT" }],
    key: uuid(),
  },
  {
    data: [{ logo: UsdtTrc, channel: "USDT_TRC20" }],
    key: uuid(),
  },
];

const happyPayChannels = {
  BankTransfer: [
    {
      data: [{ bank_name: "中信實體ATM", bank_id: "PD-ATM-CTCB" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "華南實體ATM", bank_id: "PD-ATM-HNCB" }],
      key: uuid(),
    },
    // {
    //   data: [{ bank_name: "郵局實體ATM", bank_id: "PD-ATM-POST" }],
    //   key: uuid(),
    // },
    {
      data: [{ bank_name: "上海實體ATM", bank_id: "PD-ATM-SCSB" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "中國信託WEB-ATM", bank_id: "PD-WEBATM-CTCB" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "玉山銀行WEBATM", bank_id: "PD-WEBATM-ESUN" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "華南WEB-ATM", bank_id: "PD-WEBATM-HNCB" }],
      key: uuid(),
    },
    // {
    //   data: [{ bank_name: "郵局WEB-ATM", bank_id: "PD-WEBATM-POST" }],
    //   key: uuid(),
    // },
  ],
  OTC: [
    {
      data: [{ bank_name: "全家FAMIPORT", bank_id: "PD-STORE-FAMI" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "萊爾富Life-ET", bank_id: "PD-STORE-HILIFEET" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "ibon便利生活站", bank_id: "PD-STORE-IBON" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "超商行動掃碼(7-11、全家)", bank_id: "PD-STORE-MBC" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "超商行動掃碼(萊爾富Life-ET)", bank_id: "PD-STORE-MBC-HILIFEET" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "OK超商OK-go", bank_id: "PD-STORE-OKGO" }],
      key: uuid(),
    },
  ],
  Wallet: [
    {
      data: [{ bank_name: "LINEPAY", bank_id: "PD-EPOINT-LINEPAY" }],
      key: uuid(),
    },
  ],
};

const depositExpressChannels = [
  // {
  //   data: [{ logo: Itau, bank: "itau" }],
  //   key: uuid(),
  //   "ga-data": "7",
  // },
  // {
  //   data: [{ logo: Santander, bank: "santander" }],
  //   key: uuid(),
  //   "ga-data": "8",
  // },
  // {
  //   data: [{ logo: BancoDoBrasil, bank: "banco-do-brasil" }],
  //   key: uuid(),
  //   "ga-data": "9",
  // },
  {
    data: [{ logo: Bradesco, bank: "bradesco" }],
    key: uuid(),
    "ga-data": "10",
  },
  {
    data: [{ logo: Caixa, bank: "caixa" }],
    key: uuid(),
    "ga-data": "11",
  },
];

const localPaymentBankTransferChannels = [
  {
    data: [{ logo: Itau, bank: "itau" }],
    key: uuid(),
  },
  {
    data: [{ logo: Brou, bank: "brou" }],
    key: uuid(),
  },
];

const directDebitChannels = [
  {
    data: [{ logo: Ubp, bank: "BA_UBP" }],
    key: uuid(),
    "ga-data": "223",
  },
  {
    data: [{ logo: Bpi, bank: "BA_BPI" }],
    key: uuid(),
    "ga-data": "222",
  },
];

export const pagoEfectivoChannels = [
  {
    data: [
      { logo: YapeWallet },
      { logo: PlinWallet },
      { logo: PeBanbifWallet },
      { logo: PeBbvaWallet },
      { logo: InterbankWallet },
      { logo: LigoWallet },
      { logo: ScotiabankWallet },
      { logo: TunkiWallet },
      { logo: PeScotia },
      { logo: PePambo },
      { logo: PeWu },
      { logo: PeBanbif },
      { logo: PeBbva },
      { logo: PeBcp },
      { logo: PeCaja },
      { logo: PeDisashop },
      { logo: PeInterbank },
      { logo: PeKasnet },
      { logo: PeCellPower },
      { logo: PeGanaste },
      { logo: PeMoneyGram },
      { logo: PeNiuBiz },
      { logo: PePichinca },
      { logo: PeRedDigit },
    ],
    key: uuid(),
  },
];

export const pagoEfectivoBankChannels = [
  {
    data: [
      { logo: PeBbva },
      { logo: PeBcp },
      { logo: PeInterbank },
      { logo: PeCaja },
      { logo: PeScotia },
      { logo: PeBanbif },
      { logo: PePichinca },
    ],
    key: uuid(),
  },
];

export const pagoEfectivoCashChannels = [
  {
    data: [
      { logo: PeBbva },
      { logo: PeBcp },
      { logo: PeInterbank },
      { logo: PeScotia },
      { logo: PeCaja },
      { logo: PePambo },
      { logo: PeWu },
      { logo: PeDisashop },
      { logo: PeKasnet },
      { logo: PeCellPower },
      { logo: PeGanaste },
      { logo: PeMoneyGram },
      { logo: PeNiuBiz },
      { logo: PeRedDigit },
    ],
    key: uuid(),
  },
];

export const payCashChannels = {
  ECU: [
    {
      data: [
        { logo: Pc10, key: uuid() },
        // { logo: Pc2, key: uuid() },
        { logo: Pc3, key: uuid() },
        // { logo: Pc4, key: uuid() },
        { logo: Pc11, key: uuid() },
        { logo: Pc7, key: uuid() },
        { logo: Pc9, key: uuid() },
        // { logo: Pc1, key: uuid() },
        // { logo: PePichinca, key: uuid() },
        // { logo: Azuayo, key: "Ecu-Azuayo" },
        // { logo: BancoInternacional, key: uuid() },
        // { logo: Coac29, key: uuid() },
        // { logo: Coopmego, key: uuid() },
        { logo: Economicas, key: uuid() },
        // { logo: Facilito, key: uuid() },
        // { logo: Guayaquil, key: uuid() },
        { logo: Hiper_market, key: uuid() },
        // { logo: Matualista, key: uuid() },
        // { logo: Muchuc, key: uuid() },
        { logo: CruzAzul, key: uuid() },
        // new added
        { logo: Servientrega, key: uuid() },
        { logo: Pharmacys, key: uuid() },
      ],
      key: uuid(),
    },
  ],
  MEX: [
    {
      data: [
        { logo: PcMex1, key: "MEX-Bodega" },
        { logo: PcMex2, key: uuid() },
        { logo: SamsClub, key: "MEX-SamsClub" },
        { logo: PcMex4, key: "MEX-Wallmart" },
        { logo: PcMex5, key: uuid() },
        { logo: PcMex6, key: "MEX-WallmartExpress" },
        { logo: PcMex7, key: uuid() },
        { logo: PcMex8, key: uuid() },
        // { logo: PcMex9, key: uuid() },
        { logo: PcMex10, key: uuid() },
        { logo: PcMex11, key: uuid() },
        { logo: PcMex12, key: uuid() },
        { logo: PcMex13, key: uuid() },
        { logo: PcMex14, key: uuid() },
        { logo: Santander, key: "MEX-Santander" },
        { logo: PeBbva, key: "MEX-BBVA" },
        // { logo: Ahorro, key: uuid() },
        { logo: Alianza, key: uuid() },
        // { logo: Banbajio, key: uuid() },
        { logo: Banorte, key: "MEX-Banorte" },
        // { logo: Bogeda, key: uuid() },
        // { logo: Hsbc, key: uuid() },
        { logo: Kiosko, key: uuid() },
        // { logo: Lagunitas, key: uuid() },
        // { logo: Merza, key: uuid() },
        { logo: Morelia, key: uuid() },
        { logo: Oblatos, key: uuid() },
        { logo: Smbrural, key: uuid() },
        { logo: Telecom, key: "MEX-Telecomm" },
        { logo: Via, key: uuid() },
        // { logo: Yza, key: uuid() },
        // { logo: Afirme, key: uuid() },
      ],
      key: uuid(),
    },
  ],
  PER: [
    {
      data: [
        { logo: PeBbva, key: "Per-Bbva" },
        { logo: PeKasnet, key: "Per-Kasnet" },
        { logo: PeWu, key: uuid() },
        // { logo: PeInterbank, key: uuid() },
        // { logo: PeBanbif, key: uuid() },
        // { logo: PePichinca, key: uuid() },
        { logo: PeBcp, key: "Per-Bcp" },
        // { logo: PePambo, key: uuid() },
        // { logo: PeNiuBiz, key: uuid() },
        { logo: Izipay, key: "Per-Izipay" },
        { logo: PerAndes, key: uuid() },
        { logo: PerCajadel, key: uuid() },
        { logo: PerCajapalla, key: uuid() },
        // { logo: PerFullcargo, key: uuid() },
        { logo: PerQapaq, key: uuid() },
        { logo: PerRaiz, key: uuid() },
        { logo: PerTrujillo, key: uuid() },
        // { logo: PeBanbif, key: uuid() },
        { logo: AgenteCash, key: uuid() },
        // { logo: Comercio, key: uuid() },
        { logo: Edypyme, key: uuid() },
        // { logo: Megapunto, key: uuid() },
        { logo: Sullana, key: uuid() },
      ],
      key: uuid(),
    },
  ],
  CRI: [
    {
      data: [
        // { logo: PcMex4, key: uuid() },
        { logo: CriNacional, key: "Cri-Bncr" },
        { logo: CriPayser, key: uuid() },
        { logo: Hey, key: uuid() },
      ],
      key: uuid(),
    },
  ],
  GTM: [
    {
      data: [
        // { logo: PcMex4, key: uuid() },
        { logo: GtmBam, key: "Gtm-Bam" },
        // { logo: GtmBanrural, key: uuid() },
        // { logo: GtmBantrab, key: uuid() },
        { logo: GtmContinental, key: "Gtm-Continental" },
        // { logo: Ficohsa, key: uuid() },
        // { logo: InterBanco, key: uuid() }
        // new added
        { logo: Pronet, key: "Gtm-Pronet" },
      ],
      key: uuid(),
    },
  ],
  PAN: [
    {
      data: [
        { logo: PeWu, key: uuid() },
        { logo: PanMachetazo, key: uuid() },
        { logo: PanXtra, key: uuid() },
        // { logo: PanJuan, key: uuid() },
        // { logo: Ficohsa, key: uuid() }
        // new added
        { logo: JumboMarket, key: uuid() },
        { logo: FarmaExpress, key: uuid() },
        { logo: AmPm, key: uuid() },
      ],
      key: uuid(),
    },
  ],
};

// export const payCashChannels = {
//   ECU: [
//     { data: [{ logo: Pc10 }], key: uuid() },
//     { data: [{ logo: Pc2 }], key: uuid() },
//     { data: [{ logo: Pc3 }], key: uuid() },
//     { data: [{ logo: Pc4 }], key: uuid() },
//     { data: [{ logo: Pc11 }], key: uuid() },
//     { data: [{ logo: Pc7 }], key: uuid() },
//     { data: [{ logo: Pc9 }], key: uuid() },
//     { data: [{ logo: Pc1 }], key: uuid() },
//     // { data: [{ logo: PePichinca }], key: uuid() },
//     { data: [{ logo: Azuayo }], key: uuid() },
//     // { data: [{ logo: BancoInternacional }], key: uuid() },
//     { data: [{ logo: Coac29 }], key: uuid() },
//     { data: [{ logo: Coopmego }], key: uuid() },
//     { data: [{ logo: Economicas }], key: uuid() },
//     { data: [{ logo: Facilito }], key: uuid() },
//     // { data: [{ logo: Guayaquil }], key: uuid() },
//     { data: [{ logo: Hiper_market }], key: uuid() },
//     { data: [{ logo: Matualista }], key: uuid() },
//     { data: [{ logo: Muchuc }], key: uuid() },
//     { data: [{ logo: CruzAzul }], key: uuid() },
//   ],
//   MEX: [
//     { data: [{ logo: PcMex1 }], key: uuid() },
//     { data: [{ logo: PcMex2 }], key: uuid() },
//     { data: [{ logo: SamsClub }], key: uuid() },
//     { data: [{ logo: PcMex4 }], key: uuid() },
//     { data: [{ logo: PcMex5 }], key: uuid() },
//     { data: [{ logo: PcMex6 }], key: uuid() },
//     { data: [{ logo: PcMex7 }], key: uuid() },
//     { data: [{ logo: PcMex8 }], key: uuid() },
//     { data: [{ logo: PcMex9 }], key: uuid() },
//     { data: [{ logo: PcMex10 }], key: uuid() },
//     { data: [{ logo: PcMex11 }], key: uuid() },
//     { data: [{ logo: PcMex12 }], key: uuid() },
//     { data: [{ logo: PcMex13 }], key: uuid() },
//     { data: [{ logo: PcMex14 }], key: uuid() },
//     { data: [{ logo: Santander }], key: uuid() },
//     { data: [{ logo: PeBbva }], key: uuid() },
//     // { data: [{ logo: Ahorro }], key: uuid() },
//     { data: [{ logo: Alianza }], key: uuid() },
//     // { data: [{ logo: Banbajio }], key: uuid() },
//     { data: [{ logo: Banorte }], key: uuid() },
//     // { data: [{ logo: Bogeda }], key: uuid() },
//     { data: [{ logo: Hsbc }], key: uuid() },
//     { data: [{ logo: Kiosko }], key: uuid() },
//     // { data: [{ logo: Lagunitas }], key: uuid() },
//     // { data: [{ logo: Merza }], key: uuid() },
//     { data: [{ logo: Morelia }], key: uuid() },
//     { data: [{ logo: Oblatos }], key: uuid() },
//     { data: [{ logo: Smbrural }], key: uuid() },
//     { data: [{ logo: Telecom }], key: uuid() },
//     { data: [{ logo: Via }], key: uuid() },
//     { data: [{ logo: Yza }], key: uuid() },
//     { data: [{ logo: Afirme }], key: uuid() },
//   ],
//   PER: [
//     { data: [{ logo: PeBbva }], key: "Per-Bbva" },
//     { data: [{ logo: PeKasnet }], key: "Per-Kasnet" },
//     { data: [{ logo: PeWu }], key: uuid() },
//     // { data: [{ logo: PeInterbank }], key: uuid() },
//     // { data: [{ logo: PeBanbif }], key: uuid() },
//     // { data: [{ logo: PePichinca }], key: uuid() },
//     { data: [{ logo: PeBcp }], key: "Per-Bcp" },
//     // { data: [{ logo: PePambo }], key: uuid() },
//     // { data: [{ logo: PeNiuBiz }], key: uuid() },
//     { data: [{ logo: PerAndes }], key: uuid() },
//     { data: [{ logo: PerCajadel }], key: uuid() },
//     { data: [{ logo: PerCajapalla }], key: uuid() },
//     // { data: [{ logo: PerFullcargo }], key: uuid() },
//     { data: [{ logo: PerQapaq }], key: uuid() },
//     { data: [{ logo: PerRaiz }], key: uuid() },
//     { data: [{ logo: PerTrujillo }], key: uuid() },
//     // { data: [{ logo: PeBanbif }], key: uuid() },
//     { data: [{ logo: AgenteCash }], key: uuid() },
//     // { data: [{ logo: Comercio }], key: uuid() },
//     { data: [{ logo: Edypyme }], key: uuid() },
//     // { data: [{ logo: Megapunto }], key: uuid() },
//     { data: [{ logo: Sullana }], key: uuid() },
//   ],
//   CRI: [
//     // { data: [{ logo: PcMex4 }], key: uuid() },
//     // { data: [{ logo: CriNacional }], key: uuid() },
//     { data: [{ logo: CriPayser }], key: uuid() },
//     { data: [{ logo: Hey }], key: uuid() },
//   ],
//   GTM: [
//     // { data: [{ logo: PcMex4 }], key: uuid() },
//     { data: [{ logo: GtmBam }], key: "Gtm-Bam" },
//     // { data: [{ logo: GtmBanrural }], key: uuid() },
//     // { data: [{ logo: GtmBantrab }], key: uuid() },
//     { data: [{ logo: GtmContinental }], key: "Gtm-Continental" },
//     // { data: [{ logo: Ficohsa }], key: uuid() },
//     // { data: [{ logo: InterBanco }], key: uuid() },
//   ],
//   PAN: [
//     { data: [{ logo: PeWu }], key: uuid() },
//     { data: [{ logo: PanMachetazo }], key: uuid() },
//     { data: [{ logo: PanXtra }], key: uuid() },
//     // { data: [{ logo: Ficohsa }], key: uuid() },
//   ],
// };

export const hideLuxtakLogoForAppID = (appId) => {
  return ["16394102738278966", "16448623415994515"].indexOf(appId) >= 0;
};

const countryCode2TaxIdType = {
  BRA: ["CPF", "CNPJ"],
  MEX: ["RFC", "CURP"],
  CHL: ["RUT", "RUN"],
  COL: ["NIT", "CC", "CE"],
  PER: ["DNI", "RUC"],
  ECU: ["RUC", "CE", "CI"],
  PAN: ["CIP"],
  CRI: ["CDI"],
  SLV: ["DUI"],
  GTM: ["DPI"],
  NIC: ["CIP"],
  ARG: ["DNI", "CUIT", "CUIL", "CDI"],
  URY: ["CI", "RUT"],
  BOL: ["NIT", "CI", "CE"],
  PRY: ["RUC", "CI"],
};

const latinCountries = [
  "MEX",
  "CHL",
  "COL",
  "PER",
  "ECU",
  "BRA",
  "PAN",
  "CRI",
  "SLV",
  "GTM",
  "NIC",
  "ARG",
  "URY",
  "BOL",
  "PRY",
];

const countryCode3to2 = {
  MEX: "MX",
  CHL: "CL",
  COL: "CO",
  PER: "PE",
  ECU: "EC",
  BRA: "BR",
  PAN: "PA",
  CRI: "CR",
  SLV: "SV",
  GTM: "GT",
  NIC: "NI",
  HKG: "HK",
  PHL: "PH",
  KOR: "KR",
  IDN: "ID",
  MYS: "MY",
  THA: "TH",
  CHN: "CN",
  KHM: "KH",
  SGP: "SG",
  SAU: "SA",
  ARE: "AE",
  KWT: "KW",
  QAT: "QA",
  OMN: "OM",
  IND: "IN",
  RUS: "RU",
  EGY: "EG",
  GBR: "GB",
  BHR: "BH",
  ARG: "AR",
  URY: "UY",
  BOL: "BO",
  PRY: "PY",
  AUS: "AU",
  POL: "PL",
  NZL: "NZ",
  CHE: "CH",
  CAN: "CA",
  USA: "US",
  JPN: "JP",
};

const formatCurrency = {
  BRL: "R$",
  MXN: "Mex$",
  RUB: "руб",
  USD: "US$",
  SGD: "SG$",
  CNY: "¥",
  EUR: "€",
  JPY: "JP¥",
  TRY: "₺",
};

////////////////////////////////////////////////////

const useMethodConfig = (props) => {
  const { t } = useTranslation();
  const tradeInfo = useSelector((state) => state.tradeInfo);
  const region = tradeInfo?.app_info?.region;
  const isOnlyCreditCard = tradeInfo?.channel_info_list?.some?.((item) => {
    return item.method === "CreditCard" && item.channel === "Localpayment";
  });
  const methodConfig = {
    Wallet: {
      info: {
        imageMobile: walletLogo[region]?.mobile,
        image: walletLogo[region]?.pc,
        text: t("wallet"),
      },
    },
    // Brazil
    Boleto: {
      info: {
        imageMobile: Boleto,
        image: Boleto,
        text: "Boleto",
      },
    },
    BoletoRapido: {
      info: {
        imageMobile: BoletoRapidao,
        image: BoletoRapidao,
        text: "Boleto Rápido",
      },
    },
    DepositExpress: {
      info: {
        imageMobile: DepositExpress,
        image: DepositExpress,
        text: "Depósito Bancário",
      },
    },
    Lottery: {
      info: {
        imageMobile: Lottery,
        image: Lottery,
        text: "Pagamento na Lotérica",
      },
    },
    PIX: {
      info: {
        imageMobile: Pix,
        image: Pix,
        text: "PIX",
      },
      notice: t("pix_notice"),
    },
    // Mexico
    // Paynet: {
    //   info: {
    //     imageMobile: Paynet,
    //     image: Paynet,
    //     text: "Paynet",
    //   },
    // },
    OXXO: {
      info: {
        imageMobile: Oxxo,
        image: Oxxo,
        text: t("oxxo"),
      },
    },
    SPEI: {
      info: {
        imageMobile: Spei,
        image: Spei,
        text: "SPEI",
      },
    },
    CreditCard: {
      info: {
        imageMobile: CreditCard,
        image: CreditCard,
        text: isOnlyCreditCard ? t("credit_card") : t("credit_debit_card"),
      },
    },
    DebitCard: {
      info: {
        imageMobile: CreditCard,
        image: CreditCard,
        text: t("debit_card"),
      },
    },
    RecurringCreditCard: {
      info: {
        imageMobile: CreditCard,
        image: CreditCard,
        text: t("credit_debit_card"),
      },
    },
    Cash: {
      info: {
        imageMobile: CashPayments,
        image: CashPayments,
        text: t("cash_payments"),
      },
    },
    BankTransfer: {
      info: {
        imageMobile: OnlineBanking,
        image: OnlineBanking,
        text: t("online_banking"),
      },
    },
    PagoEfectivo: {
      info: {
        imageMobile: PagoEfectivo,
        image: PagoEfectivo,
        text: t("pago_method"),
      },
    },
    PSE: {
      info: {
        imageMobile: PseM,
        image: PSE,
        text: "PSE",
      },
    },
    Efecty: {
      info: {
        imageMobile: EfectyM,
        image: Efecty,
        text: "Efecty",
      },
      faq: [
        {
          q: t("efecty_faq1q"),
          a: t("efecty_faq1a"),
        },
        {
          q: t("efecty_faq2q"),
          a: t("efecty_faq2a"),
        },
        {
          q: t("efecty_faq3q"),
          a: t("efecty_faq3a"),
        },
      ],
      video: [
        // {
        //   title: "Como pagar com banco online",
        //   source: "https://www.youtube.com/embed/74a9n3y2ClU",
        // },
      ],
    },
    Khipu: {
      info: {
        imageMobile: KhipuM,
        image: Khipu,
        text: "Khipu",
      },
      faq: [
        {
          q: t("khipu_faq1q"),
          a: t("khipu_faq1a"),
        },
        {
          q: t("khipu_faq2q"),
          a: t("khipu_faq2a"),
        },
        {
          q: t("khipu_faq3q"),
          a: t("khipu_faq3a"),
        },
      ],
    },
    GCash: {
      info: {
        imageMobile: GcashLogo,
        image: GcashLogo,
        text: "GCash",
      },
    },
    KLAP: {
      info: {
        imageMobile: Klap,
        image: Klap,
        text: "Efectivo Klap",
      },
    },
    Pago46: {
      info: {
        imageMobile: Pago46,
        image: Pago46,
        text: "Pago46",
      },
    },
    DirectDebit: {
      info: {
        imageMobile: DebitPh,
        image: DebitPh,
        text: "Direct Debit",
      },
    },
    OTC: {
      // Seven11M for xendit
      info: {
        imageMobile: RetailStore,
        image: RetailStore,
        text: t("retail_outlet"),
      },
    },
    PiPay: {
      info: {
        imageMobile: Pipay,
        image: Pipay,
        text: "Pi Pay",
      },
    },
    PayGo: {
      info: {
        imageMobile: Paygo,
        image: Paygo,
        text: "PayGo",
      },
    },
    UPI: {
      info: {
        imageMobile: UPI,
        image: UPI,
        text: "UPI",
      },
    },
    NetBanking: {
      info: {
        imageMobile: Netbank,
        image: Netbank,
        text: "Net Banking",
      },
    },
    AlfaClick: {
      info: {
        imageMobile: AlfaClick,
        image: AlfaClick,
        text: "Alfa Click",
      },
    },
    Qiwi: {
      info: {
        imageMobile: Qiwi,
        image: Qiwi,
        text: "Qiwi",
      },
    },
    WebMoney: {
      info: {
        imageMobile: WebMoney,
        image: WebMoney,
        text: "Web Money",
      },
    },
    YandexMoney: {
      info: {
        imageMobile: YandexMoney,
        image: YandexMoney,
        text: "Yandex Money",
      },
    },
    Baloto: {
      info: {
        imageMobile: Baloto,
        image: Baloto,
        text: "Baloto",
      },
      faq: [
        {
          q: t("baloto_faq1q"),
          a: t("baloto_faq1a"),
        },
      ],
    },
    SuRed: {
      info: {
        imageMobile: Sured,
        image: Sured,
        text: "Su Red",
      },
      faq: [
        {
          q: t("sured_faq1q"),
          a: t("sured_faq1a"),
        },
      ],
    },
    GANA: {
      info: {
        imageMobile: Gana,
        image: Gana,
        text: "Gana",
      },
      faq: [
        {
          q: t("gana_faq1q"),
          a: t("gana_faq1a"),
        },
      ],
    },
    OXXOPay: {
      info: {
        imageMobile: OxxoPay,
        image: OxxoPay,
        text: "OXXO PAY",
      },
    },
    UnionPay: {
      info: {
        imageMobile: UnionPay,
        image: UnionPay,
        text: "Union Pay",
      },
    },
    CoDi: {
      info: {
        imageMobile: CodiM,
        image: Codi,
        text: "CoDi",
      },
    },
    AlipayPlus: {
      info: {
        imageMobile: AlipayPlusLogo,
        image: AlipayPlusLogo,
        text: "Alipay+",
      },
    },
    VirtualAccount: {
      info: {
        imageMobile: VirtualAccount,
        image: VirtualAccount,
        text: t("virtual_account"),
      },
    },
    PlayersBankPIX: {
      info: {
        imageMobile: PlayersBank,
        image: PlayersBank,
        text: t("pb_qr"),
      },
    },
    PlayersBankCard: {
      info: {
        imageMobile: PlayersBank,
        image: PlayersBank,
        text: t("pb_card"),
      },
    },
    PagoFacil: {
      info: {
        imageMobile: PagoFacil,
        image: PagoFacil,
        text: "Pago Facil",
      },
    },
    Rapipago: {
      info: {
        imageMobile: RapiPago,
        image: RapiPago,
        text: "Rapi Pago",
      },
    },
    Sofort: {
      info: {
        imageMobile: Sofort,
        image: Sofort,
        text: "Sofort",
      },
    },
    Neosurf: {
      info: {
        imageMobile: Neosurf,
        image: Neosurf,
        text: "Neosurf",
      },
    },
    Bancontact: {
      info: {
        imageMobile: Bancontact,
        image: Bancontact,
        text: "Bancontact",
      },
    },
    Giropay: {
      info: {
        imageMobile: Giropay,
        image: Giropay,
        text: "Giro Pay",
      },
      notice: t("trustly_notice"),
    },
    iDeal: {
      info: {
        imageMobile: Ideal,
        image: Ideal,
        text: "iDeal",
      },
    },
    MyBank: {
      info: {
        imageMobile: MyBank,
        image: MyBank,
        text: "MyBank",
      },
    },
    P24: {
      info: {
        imageMobile: Przelewy,
        image: Przelewy,
        text: "Przelewy24",
      },
    },
    Trustly: {
      info: {
        imageMobile: Trustly,
        image: Trustly,
        text: "Trustly",
      },
      notice: t("trustly_notice"),
    },
    EPS: {
      info: {
        imageMobile: EPS,
        image: EPS,
        text: "EPS",
      },
    },
    SberBank: {
      info: {
        imageMobile: SberPay,
        image: SberPay,
        text: "SberPay",
      },
    },
    SberPay: {
      info: {
        imageMobile: SberPay,
        image: SberPay,
        text: "SberPay",
      },
    },
    Transfiya: {
      info: {
        imageMobile: Transfiya,
        image: Transfiya,
        text: "Transfiya",
      },
    },
    Bancolombia: {
      info: {
        imageMobile: Bancolombia,
        image: Bancolombia,
        text: "Bancolombia",
      },
    },
    BCR: {
      info: {
        imageMobile: BCR,
        image: BCR,
        text: "Banco de Costa Rica",
      },
    },
    BNCR: {
      info: {
        imageMobile: BNCR,
        image: BNCR,
        text: "Banco Nacional de Costa Rica",
      },
    },
    Servipag: {
      info: {
        imageMobile: Servipag,
        image: ServipagPc,
        text: "Servipag",
      },
    },
    WebPay: {
      info: {
        imageMobile: Webpay,
        image: Webpay,
        text: "Webpay",
      },
    },
    Crypto: {
      info: {
        imageMobile: Crypto,
        image: Crypto,
        text: t("crypto"),
      },
    },
    ToditoCash: {
      info: {
        imageMobile: ToditoCashLogo,
        image: ToditoCashLogo,
        text: "Todito Cash",
      },
    },
    OpenFinance: {
      info: {
        imageMobile: OpenFinance,
        image: OpenFinance,
        text: "Pagar com",
      },
    },
    Paysafecard: {
      info: {
        imageMobile: Paysafecard,
        image: Paysafecard,
        text: "Paysafecard",
      },
    },
    Payconiq: {
      info: {
        imageMobile: Payconiq,
        image: Payconiq,
        text: "Payconiq",
      },
    },
    Multibanco: {
      info: {
        imageMobile: Multibanco,
        image: Multibanco,
        text: "Multibanco",
      },
    },
    SBP: {
      info: {
        imageMobile: SBP,
        image: SBP,
        text: t("sbp"),
      },
    },
  };

  return methodConfig;
};

export {
  useMethodConfig,
  channelConfig,
  countryCode3to2,
  getWalletChannelLogo,
  depositExpressChannels,
  directDebitChannels,
  latinCountries,
  formatCurrency,
  checkoutConfig,
  countryCode2TaxIdType,
  localPaymentBankTransferChannels,
  cryptoChannels,
  happyPayChannels,
};
